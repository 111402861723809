import { combineReducers } from 'redux';
import typeToReducer from 'type-to-reducer';
import {
  GATEWAYS_V2_BIND_MACHINE,
  GATEWAYS_V2_BIND_ORGANIZATION,
  GATEWAYS_V2_GET_BY_ID,
  GATEWAYS_V2_LIST_MACH_ASSIGNED_TO_ORG_WITHOUT_GTW,
  GATEWAYS_V2_LOGS,
  GATEWAYS_V2_MACHINE_BY_GTW_ID,
  GATEWAYS_V2_ORGANIZATION_GATEWAYS,
  GATEWAYS_V2_UNBIND_MACHINE,
  GATEWAYS_V2_UNBIND_ORGANIZATION,
  GATEWAYS_V2_UPDATE_BY_SYSTEM
} from './constants';
import { loadingStateReducer } from '../utils';

export default combineReducers({
  loadingMachinesState: loadingStateReducer(GATEWAYS_V2_MACHINE_BY_GTW_ID),
  loadingState: loadingStateReducer(
    GATEWAYS_V2_GET_BY_ID,
    GATEWAYS_V2_MACHINE_BY_GTW_ID,
    GATEWAYS_V2_BIND_ORGANIZATION,
    GATEWAYS_V2_UNBIND_ORGANIZATION,
    GATEWAYS_V2_ORGANIZATION_GATEWAYS,
    GATEWAYS_V2_BIND_MACHINE,
    GATEWAYS_V2_UNBIND_MACHINE,
    GATEWAYS_V2_LIST_MACH_ASSIGNED_TO_ORG_WITHOUT_GTW,
    GATEWAYS_V2_LOGS,
    GATEWAYS_V2_UPDATE_BY_SYSTEM
  ),
  getGatewayById: typeToReducer(
    {
      [GATEWAYS_V2_GET_BY_ID]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  ),
  getGatewayMachineByGtwId: typeToReducer(
    {
      [GATEWAYS_V2_MACHINE_BY_GTW_ID]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {
      data: [],
      limit: 0,
      page: 0,
      search: '',
      sort: [],
      total_length: 0,
      total_pages: 0
    }
  ),
  bindOrganization: typeToReducer(
    {
      [GATEWAYS_V2_BIND_ORGANIZATION]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {
      code: 0,
      ok: false,
      message: ''
    }
  ),
  unbindOrganization: typeToReducer(
    {
      [GATEWAYS_V2_UNBIND_ORGANIZATION]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {
      code: 0,
      ok: false,
      message: ''
    }
  ),
  bindMachine: typeToReducer(
    {
      [GATEWAYS_V2_BIND_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {
      code: 0,
      ok: false,
      message: ''
    }
  ),
  unbindMachine: typeToReducer(
    {
      [GATEWAYS_V2_UNBIND_ORGANIZATION]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {
      code: 0,
      ok: false,
      message: ''
    }
  ),
  organizationGateways: typeToReducer(
    {
      [GATEWAYS_V2_ORGANIZATION_GATEWAYS]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {
      data: [],
      limit: 0,
      page: 0,
      search: '',
      sort: [],
      total_length: 0,
      total_pages: 0
    }
  ),
  listMachinesAssignedToOrgWithoutGtw: typeToReducer(
    {
      [GATEWAYS_V2_LIST_MACH_ASSIGNED_TO_ORG_WITHOUT_GTW]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {
      data: [],
      limit: 0,
      page: 0,
      search: '',
      sort: [],
      total_length: 0,
      total_pages: 0
    }
  ),
  getGatewayLogsByGtwId: typeToReducer(
    {
      [GATEWAYS_V2_LOGS]: {
        SUCCESS: (_data, { payload }) => payload
      }
    },
    {
      data: [],
      limit: 0,
      order: 'DESC',
      page: 0,
      sort: [],
      total_length: 0,
      total_pages: 0
    }
  ),
  updateGatewaysBySystem: typeToReducer(
    {
      [GATEWAYS_V2_UPDATE_BY_SYSTEM]: {
        SUCCESS: (_data, { payload }) => payload
      }
    },
    {
      code: 0,
      ok: false,
      message: ''
    }
  ),

  loadingLogsState: loadingStateReducer(GATEWAYS_V2_LOGS)
});

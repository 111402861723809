import { StyledHandlers, StyledNewDialog } from './Gateways.styled';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, Stack } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { loadGatewaysVersions } from 'redux/gateways/actions';
import { getGatewayVersions, getGatewayVersionsState } from 'redux/gateways/selectors';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gatewaysV2UpdateBySystemAction } from 'redux/gateways_v2/actions';
import { isSuccess } from 'helpers/utils';
import { actionStatus } from 'redux/utils';

const operationalSystemEnum = {
  WINDOWS: 'WINDOWS',
  LINUX: 'LINUX',
  IRIS_V3: 'IRIS_V3'
};

const UpdateGatewaysModal = ({ open, handleClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const defaultOption = 'default';
  const [operationalSystem, setOperationalSystem] = useState('WINDOWS');
  const [version, setVersion] = useState(defaultOption);

  const loadingVersionListEqual = (oldObj, newObj) => JSON.stringify(oldObj || '') === JSON.stringify(newObj || '');
  const versionList = useSelector(getGatewayVersions, loadingVersionListEqual);

  const loadingVersionListStateEqual = (oldObj, newObj) =>
    (oldObj?.loadingState?.status || false) === newObj?.loadingState?.status || false;

  const { loadingState: loadingVersionListState } = useSelector(getGatewayVersionsState, loadingVersionListStateEqual);

  const [versionByTypeList, setVersionByTypeList] = useState([]);

  useEffect(() => {
    if (isSuccess(loadingVersionListState.status)) {
      const tempVersions = versionList[operationalSystem.toLowerCase()];
      if (tempVersions && tempVersions.length > 0) {
        setVersionByTypeList(tempVersions);
      }
    }
  }, [dispatch, versionList, loadingVersionListState.status]);

  useEffect(() => {
    if (operationalSystem) dispatch(loadGatewaysVersions({ type: operationalSystem }));
  }, [dispatch, operationalSystem]);

  const handleUpdateGateways = () => {
    dispatch(
      gatewaysV2UpdateBySystemAction({
        operational_system: operationalSystem,
        version: version
      })
    );
  };

  const handleChangeRadioOperationalSystem = event => {
    setOperationalSystem(event.target.value);
    setVersion(defaultOption);
  };

  const handleChangeVersion = event => {
    setVersion(event.target.value);
  };

  const actionBar = (
    <StyledHandlers>
      <Button color="secondary" onClick={handleClose}>
        {t('default_actions.cancel')}
      </Button>
      <Button
        variant="contained"
        data-selector="license-modal-submit"
        onClick={handleUpdateGateways}
        style={{ padding: '1rem 2.5rem' }}
        disabled={version === defaultOption}
      >
        {t('gateways.update_all_gateways.update_selected')}
      </Button>
    </StyledHandlers>
  );
  return (
    <StyledNewDialog
      open={open}
      handleClose={handleClose}
      title={t('gateways.update_all_gateways.title')}
      subTitle={t('gateways.update_all_gateways.subtitle')}
      displayActionBar="true"
      actionBar={actionBar}
      maxWidth="1062px"
      content={
        <>
          <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
            <RadioGroup
              row
              aria-labelledby="operational-system-label"
              name="operational-system"
              value={operationalSystem}
              onChange={handleChangeRadioOperationalSystem}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <FormControlLabel value={operationalSystemEnum.WINDOWS} control={<Radio />} label="Windows" />
                <FormControlLabel value={operationalSystemEnum.LINUX} control={<Radio />} label="Linux" />
                <FormControlLabel value={operationalSystemEnum.IRIS_V3} control={<Radio />} label="IRIS V3" />
              </Stack>
            </RadioGroup>
          </Grid>

          <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
            <FormControl variant="filled" required fullWidth>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                labelId="version-label"
                id="version-select"
                onChange={handleChangeVersion}
                value={version}
                disabled={loadingVersionListState.status !== actionStatus.SUCCESS}
              >
                <MenuItem value={defaultOption} disabled sx={{ minHeight: '36px !important' }}>
                  {t('gateways.update_all_gateways.select_operational_system')}
                </MenuItem>
                {versionByTypeList?.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      }
    />
  );
};

UpdateGatewaysModal.propTypes = {
  open: T.bool.isRequired,
  handleClose: T.func.isRequired,
  gatewayId: T.string.isRequired,
  serialNumber: T.string,
  type: T.string,
  desiredVersion: T.string
};

export default UpdateGatewaysModal;

import axios from './axios';
import { removeEmptyValuesRecursivelyPaginated } from '../redux/utils';

/**
 * https://service-api-staging.get-notify.com/api/v2/docs#tag/Gateways/operation/GetGatewaysGatewayId
 * @param gatewayId
 * @returns {AxiosPromise}
 */
const apiGatewaysV2GetGatewayById = gatewayId =>
  axios({
    method: 'get',
    url: `/v2/gateways/${gatewayId}`
  });

/**
 * https://service-api-staging.get-notify.com/api/v2/docs#tag/Gateway-Machines/operation/
 * GetGatewaysGatewayIdMachinesAssignable
 * @param {string} gatewayId
 * @returns {AxiosPromise}
 */
const apiGatewayV2GetMachineByGatewayId = (gatewayId, params) =>
  axios({
    method: 'get',
    url: `/v2/gateways/${gatewayId}/machines`,
    params: removeEmptyValuesRecursivelyPaginated(params)
  });

/**
 * Gateways V2 - Bind organization to a gateway
 * @param {{
 *    gatewayId: string,
 *    organizationId: string
 *  }} params
 * @returns {AxiosPromise}
 */
const apiGatewayV2BindOrganization = ({ gatewayId, organizationId }) =>
  axios({
    method: 'put',
    url: `/v2/organization/${organizationId}/gateways/bind`,
    data: {
      gateway_id: gatewayId
    }
  });

/**
 * Gateways V2 - Bind organization to a gateway
 * @param {{
 *    gatewayId: string,
 *    organizationId: string
 *  }} params
 * @returns {AxiosPromise}
 */
const apiGatewayV2UnbindOrganization = ({ gatewayId, organizationId }) =>
  axios({
    method: 'put',
    url: `/v2/organization/${organizationId}/gateways/unbind`,
    data: {
      gateway_id: gatewayId
    }
  });

/**
 * Gateways V2 - List Organization Gateways
 * @param organizationId
 * @param {
 *   {
 *     serial_number: string|undefined,
 *     configured: string|undefined,
 *     limit: number|10,
 *     page: number|1,
 *     order: 'DESC'|'ASC'|undefined,
 *     sort: 'serial_number'|'last_seen'|undefined
 *   }
 * } params
 * @returns {*}
 */
const apiGatewayV2OrganizationGateways = ({ organizationId, params }) =>
  axios({
    method: 'get',
    url: `/v2/organization/${organizationId}/gateways/list`,
    params: removeEmptyValuesRecursivelyPaginated(params)
  });

/**
 * Gateways V2 - Bind organization to a gateway
 * @param {{
 *    gatewayId: string,
 *    machineId: string
 *  }} params
 * @returns {AxiosPromise}
 */
const apiGatewayV2BindMachine = ({ gatewayId, machineId }) =>
  axios({
    method: 'post',
    url: `/v2/gateways/${gatewayId}/machines/bind`,
    data: {
      gateway_id: gatewayId,
      machine_id: machineId
    }
  });

/**
 * Gateways V2 - Bind organization to a gateway
 * @param {{
 *    gatewayId: string,
 *    machineId: string
 *  }} params
 * @returns {AxiosPromise}
 */
const apiGatewayV2UnbindMachine = ({ gatewayId, machineId }) =>
  axios({
    method: 'post',
    url: `/v2/gateways/${gatewayId}/machines/unbind`,
    data: {
      gateway_id: gatewayId,
      machine_id: machineId
    }
  });

/**
 * Gateways V2 - List machines assignable to a gateway
 * @param gatewayId
 * @param {
 *  {
 *    limit: number|5,
 *    page: number|1,
 *    order: 'desc'|'asc'|undefined,
 *    sort: 'commission_number'|'time'|undefined
 *  }
 * } params
 * @returns {AxiosPromise}
 */
const apiGatewayV2GetMachineByGatewayIdAssignable = ({ gatewayId, params }) =>
  axios({
    method: 'get',
    url: `/v2/gateways/${gatewayId}/machines/assignable`,
    params: removeEmptyValuesRecursivelyPaginated(params)
  });

/**
 * https://service-api-staging.get-notify.com/api/v2/docs#tag/Gateway/operation/GetGatewaysGatewayIdInfoLogs
 * GetGatewaysGatewayIdInfoLogs
 * @param {string} gatewayId
 * @param {object} params
 * @returns {AxiosPromise}
 */
const apiGatewayV2GetLogsByGatewayId = (gatewayId, params) =>
  axios({
    method: 'get',
    url: `/v2/gateways/${gatewayId}/info/logs`,
    params: removeEmptyValuesRecursivelyPaginated(params)
  });

/**
 * https://service-api-development.get-notify.com/api/v2/docs#tag/Gateways/operation/PostGatewaysUpdateBySystem
 * PostGatewaysUpdateBySystem
 * @param {object} params
 * @returns {AxiosPromise}
 */
const apiGatewaysV2UpdateBySystem = params =>
  axios({
    method: 'post',
    url: `/v2/gateways/update-by-system`,
    data: {
      operational_system: params.params.operational_system,
      version: params.params.version
    }
  });

export {
  apiGatewaysV2GetGatewayById,
  apiGatewayV2GetMachineByGatewayId,
  apiGatewayV2BindOrganization,
  apiGatewayV2UnbindOrganization,
  apiGatewayV2OrganizationGateways,
  apiGatewayV2BindMachine,
  apiGatewayV2UnbindMachine,
  apiGatewayV2GetMachineByGatewayIdAssignable,
  apiGatewayV2GetLogsByGatewayId,
  apiGatewaysV2UpdateBySystem
};
